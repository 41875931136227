.gh-header {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: $c-white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

  &-gentiva {
    .gh-header-speak {
      @include media-breakpoint-down(xl) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      @include media-breakpoint-down(lg) {
        font-size: 15px;
      }

      @include media-breakpoint-down(md) {
        flex-direction: row;
        font-size: 17px;
      }

      @include media-breakpoint-down(tiny) {
        font-size: 14px;
      }

      a {
        margin-left: 15px;
      }
    }

    .gh-header__col {
      &-top {
        img {
          max-width: 490px;

          @include media-breakpoint-down(lg) {
            max-width: 400px;
          }

          @include media-breakpoint-down(md) {
            max-width: 100%;
          }
        }

        .gh-header-speak {
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }

      &-bot {
        @include media-breakpoint-down(md) {
          align-items: center;
          justify-content: flex-start;
        }

        .gh-header-speak {
          display: flex;

          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }

    .gh-header-nav-wrp {
      @include media-breakpoint-down(md) {
        display: flex;
      }
    }
  }

  &-nav {
    &-wrp {
      background: $c-light-gray;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &-speak {
    font-size: 20px;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      font-size: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    a {
      margin-left: 15px;

      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
  }

  &__col {
    &-top {
      height: 100px;

      @include media-breakpoint-down(md) {
        height: 70px;
      }

      img {
        height: 80px;
        max-width:200px;

        @include media-breakpoint-down(md) {
          height: 60px;
          max-width: 140px;
        }
      }
    }

    &-bot {
      height: 54px;
      display: flex;
      justify-content: flex-end;

      ul {
        list-style: none;
        height: 100%;
        display: flex;

        @include media-breakpoint-down(md) {
          display: none;
        }

        li {
          margin-left: 75px;
          height: 100%;

          @include media-breakpoint-down(xl) {
            margin-left: 50px;
          }

          @include media-breakpoint-down(lg) {
            margin-left: 25px;
          }

          a {
            font-weight: 600;
            color: $c-dark-gray;
            text-decoration: none;
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            transition: .3s ease;

            &:hover {
              color: $c-teal;

              &:before {
                opacity: 1;
                width: 100%;
              }
            }

            &:before {
              content: "";
              position: absolute;
              bottom: 0;
              background-color: $c-teal;
              height: 4px;
              width: 50%;
              left: 50%;
              transform: translateX(-50%);
              opacity: 0;
              transition: .3s ease;
            }

            &.active {
              color: $c-teal;

              &:before {
                opacity: 1;
                width: 100%;
              }
            }
          }
        }
      }

      .gh-header-speak {
        display: none;
      }
    }
  }
}