.gh-testimonial {
  padding: 50px 0;
  min-height: 390px;
  display: flex;
  align-items: center;
  background-color: $c-blue;

  @include media-breakpoint-down(md) {
    min-height: 330px;
    padding: 25px 0;
  }

  h2 {
    font-style: italic;
    color: $c-white;
    font-size: 30px;
    text-align: center;
    margin-bottom: 15px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }

  p {
    color: $c-light-gray;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }

  .swiper {
    &-slide {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 75px;

      @include media-breakpoint-down(md) {
        padding: 0 40px;
      }

      &-1 {
        h2 {
          max-width: 800px;
        }
      }

      &-2 {
        h2 {
          max-width: 1025px;
        }
      }
    }

    &-button {
      &-prev,
      &-next {
        color: $c-white;

        &:after {
          @include media-breakpoint-down(md) {
            font-size: 36px;
          }
        }
      }

      &-prev {
        left: 0;
      }

      &-next {
        right: 0;
      }
    }
  }
}