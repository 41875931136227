.gh-help {
  background: linear-gradient(0deg, rgba(233, 233, 233, 0.40) 0%, rgba(233, 233, 233, 0.40) 100%), #FFF;
  padding: 100px 0;

  @include media-breakpoint-down(md) {
    padding: 30px 0 40px;
  }

  &__col {
    &-title {
      margin-bottom: 60px;

      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }

      h2 {
        text-align: center;
      }
    }

    &-content {
      display: flex;
      align-items: center;

      &:nth-child(n+3) {
        padding-top: 60px;
      }

      @include media-breakpoint-down(xl) {
        flex-direction: column;
      }

      @include media-breakpoint-down(md) {
        &:nth-child(n+2) {
          padding-top: 40px;
        }
      }

      img {
        max-width: 220px;
        margin-right: var(--bs-gutter-x);

        @include media-breakpoint-down(xl) {
          margin-right: 0;
          margin-bottom: 20px;
          max-width: unset;
        }
      }

      h3 {
        font-size: 20px;
        margin-bottom: 10px;

        @include media-breakpoint-down(xl) {
          text-align: center;
        }
      }

      p {
        @include media-breakpoint-down(xl) {
          text-align: center;
        }
      }
    }
  }
}