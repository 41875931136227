.gh-contact {
  background: linear-gradient(0deg, rgba(233, 233, 233, 0.40) 0%, rgba(233, 233, 233, 0.40) 100%), #FFF;
  padding: 100px 0;

  @include media-breakpoint-down(md) {
    padding: 40px 0;
  }

  &__col {
    h2 {
      margin-bottom: 40px;
    }

    &-wrp {
      padding: 30px 15px;
      background-color: $c-white;
      max-width: 585px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);

      img {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
      }

      h3 {
        font-size: 18px;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        margin-bottom: 20px;
        text-align: center;

        @include media-breakpoint-down(md) {
          max-width: 300px;
        }
      }
    }
  }
}