* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent !important;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: $f-montserrat;
  font-weight: 500;
  color: $c-dark-gray;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

.container {
  @include media-breakpoint-up(xxl) {
    max-width: 1464px;
  }
}

h2 {
  font-family: $f-domine;
  color: $c-blue;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.2;

  @include media-breakpoint-down(md) {
    font-size: 30px;
  }
}

h3 {
  color: $c-blue;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

.gh-btn {
  border: 2px solid $c-teal;
  background: $c-teal;
  color: $c-white;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  padding: 15px 30px;
  transition: .3s ease;
  text-transform: uppercase;
  outline: none;

  &:hover {
    color: $c-teal;
    background: $c-white;
    cursor:pointer;
  }
}

.gh-link {
  text-decoration: underline;
  text-decoration-color: transparent;
  text-decoration-thickness: .15em;
  transition: text-decoration-color .3s;

  &-blue {
    color: $c-blue;

    &:hover {
      text-decoration-color: $c-blue;
    }
  }

  &-gray {
    color: $c-dark-gray;

    &:hover {
      text-decoration-color: $c-dark-gray;
    }
  }

  &-orange {
    color: $c-orange;

    &:hover {
      text-decoration-color: $c-orange;
    }
  }
}

.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;

  iframe,
  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-overlay {
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &-icon {
      z-index: 1;
      position: absolute;
    }
  }
}