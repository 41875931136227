.gh-footer {
  padding: 40px 0 25px;
  background-color: $c-white-1;

  &__col {
    &-top {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 30px;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: calc(var(--bs-gutter-x) * .5);
        width: calc(100% - var(--bs-gutter-x));
        height: 2px;
        background-color: $c-light-gray;
      }

      img {
        height: 60px;
        max-width: 200px;

        @include media-breakpoint-down(lg) {
          margin-bottom: 30px;
        }

        @include media-breakpoint-down(md) {
          max-width: 140px;
        }
      }

      ul {
        list-style: none;

        li {
          padding-left: 60px;

          @include media-breakpoint-down(xxl) {
            padding-left: 15px;
          }

          @include media-breakpoint-down(xl) {
            width: 50%;
            padding-left: 100px;

            &:not(:last-of-type) {
              margin-bottom: 15px;
            }
          }

          @include media-breakpoint-down(lg) {
            padding-left: 50px;
          }

          @include media-breakpoint-down(md) {
            padding-left: 0;
            width: 100%;
          }
        }
      }
    }

    &-bot {
      p {
        font-size: 12px;
        color: $c-dark-gray-2;
      }
    }
  }
}