.gh-find {
  padding: 80px 0 65px;

  @include media-breakpoint-down(md) {
    padding: 35px 0 25px;
  }

  &__col {
    &-left {
      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }

      h2 {
        max-width: 200px;
        margin-bottom: 15px;

        @include media-breakpoint-down(md) {
          text-align: center;
          max-width: unset;
        }
      }

      p {
        max-width: 275px;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }

      #zipAvailability {
        .zipcode {
          height: 40px;
          width: 182px;
          border: 1px solid $c-gray;
          text-align: center;
          font-size: 16px;
          line-height: 1;
          font-weight: 600;
          font-family: $f-montserrat;
          color: $c-dark-gray;
        }

        label {
          &.error {
            color: red;
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }

      .gh-btn {
        margin-top: 30px;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;

      &-wrp {
        display: flex;
        justify-content: center;
        align-items: center;

        .response {
          &.initial {
            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
              font-weight: 600;
				  margin-bottom:40px;

              @include media-breakpoint-down(md) {
                margin-bottom: 20px;
					 text-align:center;
              }
            }
				img {
					width:660px;
					max-width:100%;
				}
          }

          &.yes {
            max-width: 415px;
            display: none;
          }

          &.no {
            max-width: 500px;
            display: none;
          }
        }

        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 1.4;
          text-align: center;

          @include media-breakpoint-down(md) {
            font-size: 16px;
          }
        }
      }
    }
  }
}