.gh-callout {
  background: $c-blue;
  padding: 45px 0;

  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }

  &__col {
    img {
      margin-right: 20px;

      @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    p {
      color: $c-white;
      font-size: 22px;
      font-weight: 700;
      line-height: 1.4;
      max-width: 1150px;
      margin: 0;
    }
  }
}