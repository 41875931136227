.gh-why {
  padding-top: 50px;
  padding-bottom: 50px;
  height: 27.8vw;

  @media (max-width: 1500px) {
    height: auto;
  }

  @include media-breakpoint-up(md) {
    position: relative;
  }

  @include media-breakpoint-down(lg) {
    padding-top: 30px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column-reverse;
  }

  &-video {
    &-wrp {
      position: absolute;
      width: 50%;
      height: 100%;
      left: 0;
      display: flex;
      align-items: center;
      overflow: hidden;

      @include media-breakpoint-down(xl) {
        padding-right: 15px;
      }

      @include media-breakpoint-down(lg) {
        position: static;
        width: 100%;
        height: auto;
        padding-right: 0;
      }
    }
  }

  &__col {
    &-copy {
      @include media-breakpoint-down(lg) {
        margin-bottom: 30px;
      }

      h2 {
        margin-bottom: 30px;

        @include media-breakpoint-down(xl) {
          font-size: 36px;
        }

        @include media-breakpoint-down(lg) {
          font-size: 32px;
        }

        @include media-breakpoint-down(md) {
          font-size: 30px;
          margin-bottom: 20px;
        }
      }

      p {
        font-weight: 400;

        &:not(:last-of-type) {
          margin-bottom: 30px;
        }
      }
    }
  }
}