.gh-hero {
  height: 600px;
  position: relative;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(md) {
    align-items: flex-start;
    padding-top: 35px;
  }

  &:before {
    content: "";
    position: absolute;
    height: 15px;
    width: 100%;
    bottom: 0;
    background: linear-gradient(
                    to right,
                    $c-blue,
                    $c-blue 20.5%,
                    #CB3333 20.5%,
                    #CB3333 22.5%,
                    #F3B123 22.5%,
                    #F3B123 26.5%,
                    #009999 26.5%,
                    #009999 38.5%,
                    $c-blue 38.5%,
                    $c-blue
    );
  }

  &__col {
    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: center;
    }

    h1 {
      color: $c-blue;
      font-weight: normal;
      font-size: 60px;
      line-height: 110%;
      font-family: $f-domine;
      margin-bottom: 30px;
      max-width: 515px;

      @include media-breakpoint-down(xl) {
        font-size: 40px;
        max-width: 320px;
      }

      @include media-breakpoint-down(md) {
        font-size: 32px;
        max-width: 300px;
      }
    }

    p {
      margin-bottom: 30px;
      max-width: 515px;

      @include media-breakpoint-down(xxl) {
        max-width: 450px;
      }

      @include media-breakpoint-down(xl) {
        max-width: 285px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
        font-size: 14px;
        max-width: 250px;
      }
    }
  }

  picture {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      @include media-breakpoint-between(xl, xxl) {
        object-position: 42% 50%;
      }

      @include media-breakpoint-between(md, lg) {
        object-position: 58% 50%;
      }
    }
  }
}